import React from 'react';
import { Box } from '../../components/Box';
import { Header } from '../../components/Header';

import {
  BaseText,
  Container,
  Subtitle,
  Title,
  TitleSecundary,
  TableContainer,
} from './styles';

const Privacity: React.FC = () => {
  return (
    <>
      <Box>
        <Header />
      </Box>
      <Container>
        <BaseText>Atualizado em: Março de 2023</BaseText>
        <Title>Política de Privacidade</Title>
        <BaseText>
          Prezado usuário, ao fazer o cadastro na nossa plataforma você nos
          confia alguns de seus dados pessoais. Pensando nessa confiança criamos
          a nossa politica de privacidade para que sejam esclarecidas quaisquer
          dúvidas sobre a coleta, o uso e o compartilhamento dos seus dados.
          Essa politica se aplica a todos os sistemas da plataforma SUA
          ACADEMIA, seja plataforma web ou aplicativo mobile.
        </BaseText>
        <BaseText>
          Alguns dos dados solicitados são necessários para o cadastro e
          funcionamento da aplicação. Caso não queira fornecer os dados, poderá
          optar por não utilizar a plataforma.
        </BaseText>
        <BaseText>
          Alguns dados solicitados não são obrigatórios e você pode optar por
          não fornece-los sem afetar o uso da plataforma, porém, o não
          fornecimento desses dados podem dificultar o contato entre
          aluno/instituição e vice-versa.
        </BaseText>

        <Subtitle>Como coletamos seus dados</Subtitle>
        <TitleSecundary>Plataforma web</TitleSecundary>
        <BaseText>
          Através da plataforma web coletamos dados referentes ao cadastro da
          instituição e/ou do usuário gestor da instituição, além do cadastro de
          usuários através do convite enviado pelo gestor.
        </BaseText>
        <TitleSecundary>Aplicativo Mobile</TitleSecundary>
        <BaseText>
          Através da aplicação mobile, disponível para android pela loja Google
          Play, coletamos os mesmos dados mencionados acima.
        </BaseText>
        <TitleSecundary>Canais de Atendimento</TitleSecundary>
        <BaseText>
          Podemos coletar informações enviadas por canais de atendimento para
          melhorias na nossa plataforma.
        </BaseText>
        <TitleSecundary>Plataforma de terceiros</TitleSecundary>
        <BaseText>
          plataformas de terceiros, como, Instagram, WhatsApp. Essas plataformas
          possuem as próprias politicas de privacidade. Após a coleta dos dados
          e disponibilização para a plataforma SUA ACADEMIA os dados são
          tratados de acordo com a nossa politica. Para mais informações sobre a
          forma que os dados serão tratados por plataformas de terceiros,
          sugerimos que sejam lidas as politicas de privacidades destas
          plataformas.
        </BaseText>

        <Subtitle>O que coletamos</Subtitle>
        <BaseText>
          Durante seu cadastro e utilização da nossa plataforma, coletamos
          alguns dados pessoais para o funcionamento de todos os serviços
          disponibilizados. Os dados são usados para identificação de usuários,
          comunicação entre instituição e usuários, marketing e funcionamento
          básico da aplicação, como cadastro, login e atribuições de treinos.
          Abaixo é possível ver a descrição dos dados pessoais coletados e como
          serão utilizados.
        </BaseText>
        <TitleSecundary>Dados da instituição</TitleSecundary>
        <BaseText>
          <TableContainer>
            <table>
              <tr>
                <th>Grupo</th>
                <th>Dados</th>
                <th>Finalidade</th>
              </tr>
              <tr>
                <th>Cadastrais</th>
                <td>
                  <ul>
                    <li>Nome;</li>
                    <li>Logo;</li>
                    <li>Telefone;</li>
                    <li>Endereço.</li>
                  </ul>
                </td>
                <td>
                  São dados coletados para cadastramento e identificação da
                  instituição na plataforma. A instituição pode ser exibida na
                  landing page da plataforma, disponível no link
                  http://www.suaacademia.com/, como forma de exibir instituições
                  que usam os nossos serviços. O telefone e endereço são dados
                  opcionais compartilhados com os usuários cadastrado na
                  instituição.
                </td>
              </tr>
            </table>
          </TableContainer>
        </BaseText>

        <TitleSecundary>Dados de usuário</TitleSecundary>
        <BaseText>
          <TableContainer>
            <table>
              <tr>
                <th>Grupo</th>
                <th>Dados</th>
                <th>Finalidade</th>
              </tr>
              <tr>
                <th>Cadastrais</th>
                <td>
                  <ul>
                    <li>Nome completo;</li>
                    <li> E-mail;</li>
                    <li>Data de nascimento;</li>
                    <li>Telefone;</li>
                    <li>Endereço.</li>
                  </ul>
                </td>
                <td>
                  São dados coletados para cadastramento e identificação do
                  usuário na plataforma. O telefone e endereço são dados
                  opcionais compartilhados com a instituição que o usuário está
                  cadastrado.
                </td>
              </tr>
              <tr>
                <th>Atendimento</th>
                <td>
                  Coletamos dados de atendimento como elogios, reclamações,
                  sugestões, entre outras mensagens, que podem incluir fotos,
                  vídeos e áudios.
                </td>
                <td>
                  Os dados serão usados como feedback para melhorias na
                  plataforma.
                </td>
              </tr>
            </table>
          </TableContainer>
        </BaseText>

        <BaseText>
          De forma geral, os dados serão utilizados para manutenção da
          aplicação, melhorias nos serviços, desenvolvimento de novas
          funcionalidades, pleno funcionamento da aplicação e marketing, que
          incluem campanhas de divulgação da plataforma e promoções.
        </BaseText>

        <Subtitle>Dados de Crianças e Adolescentes</Subtitle>
        <BaseText>
          A plataforma SUA ACADEMIA e aplicativos são destinados a gestores e
          usuários de academias, que por si só já devem ter pelo menos 18 anos.
          Contudo, solicitamos no cadastro a data de nascimento real para
          validação desta informação. Caso seja detectado que o usuário
          cadastrado não tem a idade mínima, o acesso será imediatamente
          bloqueado, e caso seja necessário, excluído.
        </BaseText>

        <Subtitle>Compartilhamento de Dados Pessoais</Subtitle>
        <BaseText>
          Para o funcionamento pleno da plataforma, compartilhamos os dados
          coletados entre aluno e instituição. Além disso, alguns dados
          descritos da seção O que coletamos podem ser usados mara o marketing
          dos nossos serviços. Quando aplicados, seus dados podem ser
          compartilhados com:
        </BaseText>
        <BaseText>
          <ul>
            <li>Gestor/Aluno;</li>
            <li>Usuários que acessarem a landing page;</li>
            <li>Provedores de armazenamento de dados;</li>
            <li>
              Mediante consentimento, de maneiras diferentes das listadas nesta
              política.
            </li>
          </ul>
        </BaseText>

        <Subtitle>Medidas de segurança adotadas</Subtitle>
        <BaseText>
          Diante a confiança dada a nós dos seus dados, estamos comprometidos
          com a segurança das informações fornecidas. A plataforma SUA ACADEMIA
          está alinhada com os pilares da segurança da informação, presando a
          Confidencialidade, Integridade e Disponibilidade dos dados pessoais
          dos usuários. Buscamos sempre tornar mais efetivas as medidas de
          segurança, para isso, algumas das nossas medidas para esse feito são:
        </BaseText>
        <BaseText>
          <ul>
            <li>
              Disponibilizar os dados apenas a quem a sua disponibilização é
              imprescindível para o funcionamento da plataforma;
            </li>
            <li>Armazenamento e compartilhamento de forma segura;</li>
            <li>Criptografia de senhas;</li>
          </ul>
        </BaseText>

        <Subtitle>Seus Direitos</Subtitle>
        <BaseText>
          Visamos atender os requisitos de todos os usuários, conforme definido
          pela Lei Geral de proteção de dados, que incluem:
        </BaseText>
        <BaseText>
          <ul>
            <li>Acessar os dados disponibilizados a qualquer momento;</li>
            <li>
              Retificação e alteração dos dados caso estejam incompletos ou
              incorretos;
            </li>
            <li>Exclusão dos dados caso seja de seu desejo;</li>
            <li>
              Informação do uso e atualização da utilização dos dados fornecidos
              a nossa plataforma;
            </li>
            <li>
              Revogação do consentimento, que implica na exclusão do cadastro e
              de todos os dados fornecidos.
            </li>
          </ul>
        </BaseText>
        <BaseText strong>
          A plataforma reserva o direito de cancelar o serviço mediante aviso
          prévio.
        </BaseText>

        <Subtitle>Atualização desta Política de Privacidade</Subtitle>
        <BaseText>
          Ao atualizarmos as nossas politicas de privacidade, elas sempre
          estarão disponíveis nos através do link, para que possam ser revisados
          pelos usuários sempre que necessário.
        </BaseText>
        <BaseText>
          Qualquer dúvida sobre os nossos termos podem ser tratadas através do
          e-mail suporte@suaacademia.com. Responderemos com maior brevidade
          possível.
        </BaseText>
      </Container>
    </>
  );
};

export { Privacity };
