import styled, { css } from 'styled-components';
import { ButtonOptions } from '../ButtonOptions';

export const Container = styled.div`
  width: 100%;
  height: 100px;
  background-color: #515151;

  display: flex;
  justify-content: center;

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    height: 80px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 20px 0;

  max-width: 1400px;
  min-width: 1199px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1199px) {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: auto;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoImage = styled.img`
  width: 60px;
  height: 60px;
`;

export const LogoText = styled.div`
  font-family: 'Black Ops One', cursive;
  font-size: 18px;
  color: #f5f5f5;

  margin-left: 20px;
`;

export const Button = styled(ButtonOptions)`
  margin-left: 10px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
`;
