import styled, { css } from 'styled-components';

const fontTitle = 72;
const fontSubtitle = 48;
const fontTitleSecondary = 32;
const fontBaseText = 21;

export const Container = styled.div`
  width: 1199px;
  margin: 40px auto;

  @media (max-width: 1199px) {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-size: ${fontTitle}px;
  font-weight: 500;

  margin: 40px 0;

  @media (max-width: 1199px) {
    font-size: ${fontTitle - 5}px;
  }
`;

export const Subtitle = styled.h3`
  text-align: left;
  font-size: ${fontSubtitle}px;
  font-weight: 400;

  margin: 30px 0;

  @media (max-width: 1199px) {
    font-size: ${fontSubtitle - 5}px;
  }
`;

export const TitleSecundary = styled.h4`
  text-align: left;
  font-size: ${fontTitleSecondary}px;
  font-weight: 400;

  margin-top: 30px;

  @media (max-width: 1199px) {
    font-size: ${fontTitleSecondary - 5}px;
  }
`;

interface baseTextProps {
  strong?: boolean;
}

export const BaseText = styled.p<baseTextProps>`
  text-align: left;
  font-size: ${fontBaseText}px;
  font-weight: 300;
  margin: 10px 0;

  ${props =>
    props.strong &&
    css`
      font-weight: 600;
    `}

  & > ul {
    padding-left: 50px;
  }

  table {
    width: 100%;

    * {
      border: 1px solid #151515;
    }
    ul {
      border: none;
    }
    li {
      list-style: none;
      border: none;
    }
    th {
      background-color: #515151;
      color: #f5f5f5;
      padding: 5px 10px;
    }
    td {
      padding: 5px 10px;
    }

    th:first-child {
      width: 15%;
    }
  }

  @media (max-width: 1199px) {
    width: 100%;
    width: calc(100%);
    font-size: ${fontBaseText - 5}px;
  }
`;

export const TableContainer = styled.div`
  @media (max-width: 1199px) {
    width: calc(100%);
    overflow-x: scroll;
  }
`;
