import React, { HTMLAttributes, HtmlHTMLAttributes, useCallback } from 'react';

import {
  Button,
  Container,
  ContainerButtons,
  Content,
  LogoContainer,
  LogoImage,
  LogoText,
} from './styles';

import logoImg from '../../assets/logo.svg';

type componentProps = HtmlHTMLAttributes<HTMLDivElement>;

const Header: React.FC<componentProps> = ({ ...rest }) => {
  const handleNavigateLogin = useCallback(() => {
    window.open('http://app.suaacademia.com/', '_blank');
  }, []);

  const handleNavigateRegister = useCallback(() => {
    window.open('http://app.suaacademia.com/new-gym/', '_blank');
  }, []);

  return (
    // <Container {...rest}>
    <Content {...rest}>
      <LogoContainer>
        <LogoImage src={logoImg} alt="logo" />
        <LogoText>SUA ACADEMIA</LogoText>
      </LogoContainer>

      <ContainerButtons>
        <Button
          name="signIn"
          value="Cadastrar"
          onClick={handleNavigateRegister}
        />
        <Button name="signOn" value="Entrar" onClick={handleNavigateLogin} />
      </ContainerButtons>
    </Content>
    // </Container>
  );
};

export { Header };
