import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 200px;
  background-color: #515151;

  img {
    margin-top: -50px;
    margin-left: 40px;

    width: 100px;
    height: 100px;
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    min-height: 80px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: 20px 0;
  margin-bottom: 25px;

  max-width: 1400px;
  min-width: 1199px;

  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ul li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  ul li a {
    color: #f5f5f580;
    transition: color 0.2s;

    &:hover {
      color: #f5f5f5;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 700;

    color: #f5f5f5;
  }

  .left {
    display: flex;
    flex: 1;
    align-self: flex-end;
  }

  .right {
    display: flex;

    flex-direction: column;

    align-items: flex-end;

    .margin-bottom {
      padding-bottom: 15px;
    }

    img {
      width: 25px;
      height: 25px;
      margin: 0;
      padding: 0;

      /* &:hover {
        filter: invert(60%) sepia(100%) saturate(0%) hue-rotate(248deg)
          brightness(106%) contrast(106%);
      } */
    }

    h2 {
      text-align: right;
      width: 210px;
    }

    ul {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    ul li {
      cursor: pointer;
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  @media (max-width: 1199px) {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    width: calc(100% - 40px);
    margin: auto;

    flex-direction: column-reverse;

    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
`;
