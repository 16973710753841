import styled, { css } from 'styled-components';

interface containerProps {
  highlight?: boolean;
}

export const Container = styled.div<containerProps>`
  /* width: 210px !important; */

  width: 210px;
  height: 350px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  cursor: pointer;

  border-radius: 20px;
  box-shadow: 0 2px 6px 2px #15151580;
  transition: transform 0.2s;

  ${props =>
    props.highlight &&
    css`
      transform: scale(1.15);
      background-color: #ff955f;
      color: #f5f5f5;
    `}

  h2.title-subscription {
    font-size: 13px;
    font-weight: 700;

    text-align: left;

    width: calc(100% - 40px);
    margin: 0 auto;
  }

  h3.subtitle-subscription {
    font-size: 12px;
    color: #15151580;

    text-align: left;

    width: calc(100% - 40px);
    margin: 0 auto;

    span {
      font-size: 27px;
      color: #151515;
      font-weight: 700;
      margin: 0 5px;

      ${props =>
        props.highlight &&
        css`
          color: #f5f5f5;
        `}
    }

    ${props =>
      props.highlight &&
      css`
        color: #f5f5f580;
      `}
  }

  p.description-subscription {
    width: calc(100% - 40px);
    margin: 25px auto;

    font-size: 13px;
  }

  div.details {
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    font-size: 15px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 3px;

      ${props =>
        props.highlight &&
        css`
          filter: invert(99%) sepia(33%) saturate(53%) hue-rotate(233deg)
            brightness(113%) contrast(92%);
        `}
    }

    margin-right: 5px;
  }

  .left {
    display: flex;
    justify-content: space-between;
  }

  .right {
    color: #15151580;

    ${props =>
      props.highlight &&
      css`
        color: #f5f5f580;
      `}
  }

  &:hover {
    transform: scale(1.15);
    background-color: #ff955f;
    color: #f5f5f5;

    h3.subtitle-subscription {
      color: #f5f5f580;

      span {
        color: #f5f5f5;
      }
    }

    div.details {
      img {
        filter: invert(99%) sepia(33%) saturate(53%) hue-rotate(233deg)
          brightness(113%) contrast(92%);
      }

      .right {
        color: #f5f5f580;
      }
    }
  }

  @media (max-width: 1199px) {
    min-width: 210px;
  }
`;
