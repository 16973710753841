/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Link } from 'react-router-dom';
import { Container, Content } from './styles';

import logoIcon from '../../assets/logo.svg';
import instagramIcon from './assets/instagram.svg';
import whatsappIcon from './assets/whatsapp.svg';

const Footer: React.FC = () => {
  const ddd = 84;
  const phone = 991153607;
  const text = 'Olá, tenho interesse na assinatura do app.';

  return (
    <Container>
      <img src={logoIcon} alt="logo" />
      <Content>
        <div className="left">
          <ul>
            <li>
              <Link to="privacity">Políticas de privacidade</Link>
            </li>
          </ul>
        </div>

        <div className="right">
          <h2>Dúvidas, criticas ou sugestões?</h2>
          <ul className="margin-bottom">
            <li>
              <a href="mailto:suporte@suaacademia.com">
                suporte@suaacademia.com
              </a>
            </li>
          </ul>

          <h2>Ou em nossas redes</h2>
          <ul>
            <li
              onClick={() =>
                window.open('https://www.instagram.com/suaacademia/')
              }
            >
              <img src={instagramIcon} alt="instagram" />
            </li>
            <li
              onClick={() =>
                window.open(
                  `https://web.whatsapp.com/send?phone=55${ddd}${phone}&text=${text}`,
                )
              }
            >
              <img src={whatsappIcon} alt="whatsapp" />
            </li>
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export { Footer };
