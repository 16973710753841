import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ComingSoon } from '../pages/ComingSoon';
import { Homepage } from '../pages/Homepage';
import { Privacity } from '../pages/Privacity';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/privacity" element={<Privacity />} />
    </Routes>
  );
};

export { Router };
