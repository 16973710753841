import React, { useEffect, useRef, useState } from 'react';

import {
  ButtonExternal,
  Container,
  SectionGoToRegister,
  SectionHome,
  SectionSubscriptions,
  SectionTargetAudience,
  SectionToolsManagers,
  SectionToolsUsers,
  Slider,
} from './styles';

import { Box } from '../../components/Box';
import { Header } from '../../components/Header';

import logoAppImg from './assets/app_logo.png';

import appDashboardImg from './assets/dashboard.png';
import appCurrentTrainingImg from './assets/current_training.png';
import appTrainingsDayImg from './assets/trainings_of_day.png';

import modalNewTraining from './assets/modal_add_training.png';
import modalNewExercise from './assets/modal_new_exercise.png';
import modalChangeTraining from './assets/modal_change_training.png';

import bikeImg from './assets/bike.svg';
import squatJerkImg from './assets/squat_jerk.svg';
import ballImg from './assets/ball.svg';

import webIcon from './assets/web.svg';
import androidIcon from './assets/android.svg';
import appleIcon from './assets/apple.svg';

import { CardSubscription } from '../../components/CardSubscription';
import { api } from '../../services/api';
import { Footer } from '../../components/Footer';

interface cardSubscriptionsProps {
  id: string;
  name: string;
  price: number;
  max_users: number;
  max_exercises: number;
  max_news: number;
  description: string;
}

const Homepage: React.FC = () => {
  const [subscriptions, setSubscriptios] = useState(
    [] as cardSubscriptionsProps[],
  );

  useEffect(() => {
    api.get('subscriptions').then(response => setSubscriptios(response.data));
  }, []);

  return (
    <Container>
      <Box>
        <Header />
        <SectionHome>
          <h2>
            A <span>plataforma completa</span> para sua academia
          </h2>
          <h3>
            Gerencie treinos, crie exercicios personalizados e modernize a sua
            academia. Ideal também para personal trainers.
          </h3>

          <img src={logoAppImg} alt="Logo_image" />
        </SectionHome>
        {/* <div id="separate" /> */}
      </Box>

      <SectionToolsManagers>
        <h2>Ferramentas para gestores e personal trainers</h2>
        <div>
          <div className="list">
            <ul>
              <li>Elaboração de treinos de forma rápida e prática;</li>
              <li>Criação de exercicios personalizados com foto e descrição</li>
              <li>
                Inclusão de mensagens individuais para os alunos nos exercícios;
              </li>
              <li>Envio de avisos para todos os alunos;</li>
            </ul>
            <div>
              <h3>
                Plataforma do <span>gestor</span> disponível para:
              </h3>
              <div className="row">
                <ButtonExternal
                  img={webIcon}
                  name="Web"
                  value="Web"
                  onClick={() => window.open('http://app.suaacademia.com/')}
                />
              </div>
            </div>
          </div>
          <div className="images">
            <div>
              <img
                src={modalNewTraining}
                alt="modal_new_training"
                className="img1"
              />
              <img
                src={modalNewExercise}
                alt="modal_new_exercise"
                className="img2"
              />
              <img
                src={modalChangeTraining}
                alt="modal_change_training"
                className="img3"
              />
            </div>
          </div>
        </div>
      </SectionToolsManagers>

      <SectionToolsUsers>
        <h2>Ferramentas para alunos</h2>
        <div>
          <div className="images">
            <img
              src={appTrainingsDayImg}
              alt="app_trainings_day"
              className="img2"
            />
            <img src={appDashboardImg} alt="app_dashboard" className="img1" />
            <img
              src={appCurrentTrainingImg}
              alt="app_training_current"
              className="img3"
            />
          </div>
          <div className="list">
            <ul>
              <li>Visualização do progresso semanal;</li>
              <li>Visualização do progresso diário;</li>
              <li>Registro da carga utilizada nos exercícios;</li>
            </ul>

            <div>
              <h3>
                Plataforma do <span>aluno</span> disponível para:
              </h3>
              <div className="row">
                <ButtonExternal
                  img={androidIcon}
                  name="android"
                  value="Google Play"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.pedromesmer.suaacademia',
                    )
                  }
                />
                {/* <ButtonExternal
                  img={appleIcon}
                  name="apple"
                  value="App Store"
                /> */}
              </div>
              <h3>
                Em breve para <span>iPhone</span> e <span>navegadores web</span>
                .
              </h3>
            </div>
          </div>
        </div>
      </SectionToolsUsers>

      <Box>
        <SectionTargetAudience>
          <h2>
            Planejado para <span>você</span>
          </h2>
          <div>
            <div className="detail-target-audience">
              <p>
                <span>Gestor de academias</span> que deseja modernizar neu
                negócio e ter mais praticidade no dia a dia.
              </p>
              <div>
                <img src={bikeImg} alt="bike" />
              </div>
            </div>

            <div className="detail-target-audience">
              <div>
                <img src={squatJerkImg} alt="squat_jerk" />
              </div>
              <p>
                <span>Personal trainer</span> independente que deseja uma
                ferramenta que o ajude com o gerenciamento treinos dos seus
                alunos.
              </p>
            </div>
          </div>
        </SectionTargetAudience>
      </Box>

      <SectionSubscriptions>
        <h2>Planos disponíveis</h2>
        <p>
          Simples e prático, contrate e tenha acesso as ferramentas da hora.
        </p>

        <Slider>
          {subscriptions.map(sub => {
            return (
              <CardSubscription
                key={sub.id}
                name={sub.name.toLocaleUpperCase()}
                description={sub.description}
                exercises={sub.max_exercises}
                users={sub.max_users}
                notifications={sub.max_news}
                value={sub.price}
              />
            );
          })}
        </Slider>

        <p>
          Para contratar um dos nossos planos, faça o cadastro da sua
          instituição abaixo. A contratação do serviço deve ser realizada dentro
          da plataforma.
        </p>
        <p>Em breve, novos planos e condições especiais estarão disponíveis.</p>
      </SectionSubscriptions>

      <SectionGoToRegister>
        <div className="left">
          <h2>Gostou?</h2>
          <h2>Faz um teste conosco!</h2>
          <h3>
            Disponibizamos um período de teste feito pra você, sem compromisso.
          </h3>

          <ButtonExternal
            name="apple"
            value="Cadastrar academia"
            onClick={() =>
              window.open('http://app.suaacademia.com/new-gym/', '_blank')
            }
          />
        </div>

        <div className="right">
          <div>
            <img src={ballImg} alt="ball" />
          </div>
        </div>
      </SectionGoToRegister>

      <Box>
        <Footer />
      </Box>
    </Container>
  );
};

export { Homepage };
