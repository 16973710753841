import React, { InputHTMLAttributes } from 'react';
import { Header } from '../Header';

import { Container } from './styles';

interface buttonProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  imageMobile?: string;
}

const ButtonOptions: React.FC<buttonProps> = ({
  name,
  value,
  imageMobile,
  ...rest
}) => {
  return (
    <Container imageMobile={!!imageMobile} {...rest}>
      <span>{value}</span>
      <img src={imageMobile} alt="iconMobile" />
    </Container>
  );
};

export { ButtonOptions };
