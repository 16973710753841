import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  justify-content: center;
  gap: 30px;

  @media (max-width: 1199px) {
    justify-content: flex-start;

    width: calc(100% - 40px);

    overflow-x: scroll;
    padding: 35px 20px;
  }
`;
