import React, { HTMLAttributes, HtmlHTMLAttributes } from 'react';

import { BoxStyle } from './styles';

interface IBoxProps extends HTMLAttributes<HTMLDivElement> {
  background?: string;
}

const Box: React.FC<IBoxProps> = ({ children, background = '545454' }) => {
  return <BoxStyle bg={background}>{children}</BoxStyle>;
};

export { Box };
