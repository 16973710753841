import React, { Children, HtmlHTMLAttributes } from 'react';

import { Container } from './styles';

interface sliderProps extends HtmlHTMLAttributes<HTMLDivElement> {
  name?: string;
}

const Slider: React.FC<sliderProps> = ({ name, children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export { Slider };
