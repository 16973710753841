import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IButtonProps {
  imageMobile?: boolean;
}

export const Container = styled.button<IButtonProps>`
  min-width: 220px;
  height: 60px;

  display: flex;
  cursor: pointer;

  justify-content: center;
  align-items: center;

  border: none;

  background-color: #ff955f;

  color: #f5f5f5;
  font-size: 32px;

  border-radius: 40px;

  transition: background-color 0.2s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: ${shade(0.2, '#ff955f')};
  }

  span {
    font-size: 20px;
    flex: 1;

    margin: auto 10px;
  }

  img {
    /* display: none; */
    width: 40px;
    height: 40px;

    margin-left: 10px;
  }

  @media (max-width: 1199px) {
    width: auto;
    height: 45px;
    min-width: auto;

    span {
      font-size: 15px;
      padding: 0 10px;
    }

    img {
      display: block;
      width: 30px;
      height: 30px;
    }

    ${props =>
      props.imageMobile &&
      css`
        span {
          display: none;
        }

        img {
          display: block;
          width: 25px;
          height: 25px;
          margin: 5px;
        }
      `}
  }
`;
