import React, { ButtonHTMLAttributes, InputHTMLAttributes } from 'react';
import { Header } from '../Header';

import { Container } from './styles';

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  value: string;
  imageMobile?: string;
  img?: string;
}

const ButtonAccess: React.FC<buttonProps> = ({
  name,
  value,
  imageMobile,
  img,
  ...rest
}) => {
  return (
    <Container imageMobile={!!imageMobile} {...rest}>
      {img && <img src={img} alt="icon" />}
      <span>{value}</span>
      {/* <img src={imageMobile} alt="iconMobile" /> */}
    </Container>
  );
};

export { ButtonAccess };
