import React, {
  DialogHTMLAttributes,
  HTMLAttributes,
  DOMAttributes,
  useRef,
  HtmlHTMLAttributes,
  useEffect,
} from 'react';

import bellIcon from './assets/bell.svg';
import halterIcon from './assets/halter.svg';
import usersIcon from './assets/users.svg';

import { Container } from './styles';

interface cardProps extends HtmlHTMLAttributes<HTMLDivElement> {
  highlight?: boolean;
  name?: string;
  value?: number;
  description?: string;
  notifications?: number;
  exercises?: number;
  users?: number;
}

const CardSubscription: React.FC<cardProps> = ({
  name,
  value,
  description,
  notifications,
  exercises,
  users,
  highlight,
  ...rest
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  return (
    <Container ref={elementRef} highlight={highlight} {...rest}>
      <h2 className="title-subscription">{name}</h2>
      <h3 className="subtitle-subscription">
        R$<span>{value?.toFixed(2)}</span>/mês
      </h3>

      <p className="description-subscription">{description}</p>

      <div className="details">
        <div className="left">
          <img src={bellIcon} alt="bell_icon" />
          <span>Notificações</span>
        </div>
        <span className="right">{notifications}</span>
      </div>

      <div className="details">
        <div className="left">
          <img src={halterIcon} alt="bell_icon" />
          <span>Exercícios</span>
        </div>
        <span className="right">{exercises}</span>
      </div>

      <div className="details">
        <div className="left">
          <img src={usersIcon} alt="bell_icon" />
          <span>Usuários</span>
        </div>
        <span className="right">{users}</span>
      </div>
    </Container>
  );
};

export { CardSubscription };
