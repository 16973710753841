import styled from 'styled-components';
import { ButtonAccess } from '../../components/ButtonAccess';
import { Slider as SliderComponent } from '../../components/Slider';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  /* background-color: #313131; */

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    overflow: hidden;
  }
`;

export const ButtonExternal = styled(ButtonAccess)``;

export const SectionHome = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1400px;
  min-width: 1200px;
  width: 100%;

  min-height: 500px;

  margin: 0 auto;

  h2,
  h3 {
    text-align: center;
    color: #f5f5f5;

    margin: 0 auto;
  }

  h2 {
    margin-top: 30px;

    font-size: 52px;
    font-weight: 700;

    max-width: 600px;

    & > span {
      color: #ff955f;
    }
  }

  h3 {
    margin-top: 30px;
    max-width: 700px;
    font-size: 22px;
  }

  img {
    height: 530px;
    width: 260px;

    margin: 0 auto;

    margin-top: 30px;
    margin-bottom: -150px;
    /* padding-bottom: 150px; */
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    h2 {
      font-size: 36px;
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 18px;
      width: calc(100% - 40px);
      margin: auto;
    }
  }
`;

export const SectionToolsManagers = styled.div`
  padding-top: 230px;

  display: flex;
  flex-direction: column;

  max-width: 1400px;
  min-width: 1200px;
  width: 100%;

  margin: auto;

  h2 {
    font-size: 48px;
    text-align: center;
    font-weight: 700;

    max-width: 900px;
    margin: 0 auto;
  }

  & > div {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  div.list {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
      max-width: 80%;
      margin: 0 auto;
      list-style: none;

      li {
        font-size: 28px;
        margin-top: 20px;

        &::before {
          content: '•';
          color: #ff955f;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    div {
      margin: 60px auto 0;
      width: 100%;

      .row {
        display: flex;
        margin: 0;

        justify-content: space-evenly;
      }

      h3 {
        font-size: 21px;
        text-align: center;

        margin-bottom: 10px;

        span {
          font-weight: 700;
        }
      }
      button {
        margin: 5px auto;
      }
    }
  }

  div.images {
    width: 45%;

    img {
      height: 325px;
      box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.25);
      position: relative;
      transition: transform 0.5s;
    }

    img.img1 {
      z-index: 1;
      /* transform: translate(20%, 0); */

      &:hover {
        z-index: 15;
        transform: scale(1.05);
      }
    }

    img.img2 {
      z-index: 2;
      margin-left: -30%;
      margin-bottom: -20%;
      /* transform: translate(-30%, 50%); */

      &:hover {
        z-index: 15;
        transform: scale(1.05);
      }
    }

    img.img3 {
      z-index: 3;
      margin-top: -10%;
      margin-left: 10%;
      /* transform: translate(50%, -20%); */

      &:hover {
        z-index: 15;
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    h2 {
      font-size: 36px;
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 15px;
    }

    & > div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    div.list {
      width: calc(100% - 40px);
      margin: auto;

      ul {
        width: 100%;
        margin: auto;
      }

      ul li {
        font-size: 21px;
      }

      /* h3 {
        margin-bottom: 10px;
      } */

      .row {
        flex-direction: column;
      }
    }

    div.images {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;

      display: none;

      & > div {
        transform: scale(0.6);
        min-width: 550px;

        img.img1 {
          z-index: 1;

          &:hover {
            z-index: 15;
            transform: scale(1.05);
          }
        }

        img.img2 {
          z-index: 2;
          margin-left: -30%;
          margin-bottom: -20%;

          &:hover {
            z-index: 15;
            transform: scale(1.05);
          }
        }

        img.img3 {
          z-index: 3;
          margin-top: -10%;
          margin-left: 10%;

          &:hover {
            z-index: 15;
            transform: scale(1.05);
          }
        }
      }
    }
  }
`;

export const SectionToolsUsers = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;

  display: flex;
  flex-direction: column;

  max-width: 1400px;
  min-width: 1200px;
  width: 100%;

  h2 {
    font-size: 48px;
    text-align: center;
    font-weight: 700;

    max-width: 900px;
    margin: 0 auto;
  }

  & > div {
    margin: 50px 0;
    display: flex;
    justify-content: space-evenly;
  }

  div.list {
    min-width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
      max-width: 80%;
      margin: 0 auto;
      list-style: none;

      li {
        font-size: 28px;
        margin-top: 20px;

        &::before {
          content: '•';
          color: #ff955f;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    div {
      margin: 60px auto 0;
      width: 100%;

      .row {
        display: flex;
        margin: 0;

        justify-content: space-evenly;
      }

      h3 {
        font-size: 21px;
        text-align: center;

        margin-bottom: 10px;

        span {
          font-weight: 700;
        }
      }
      button {
        margin: 5px auto;
      }
    }
  }

  div.images {
    /* width: auto; */
    position: relative;

    img {
      height: 325px;
      box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.25);
      position: relative;
      transition: transform 0.5s;
      border-radius: 25px;
    }

    img.img1 {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 25%;
      transform: scale(1.05);

      &:hover {
        z-index: 15;
        transform: scale(1.1);
      }
    }

    img.img2 {
      z-index: 2;
      rotate: -16deg;

      &:hover {
        z-index: 15;
        transform: scale(1.05);
      }
    }

    img.img3 {
      z-index: 2;
      rotate: 16deg;

      &:hover {
        z-index: 15;
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    padding-top: 30px;
    padding-bottom: 30px;

    h2 {
      font-size: 36px;
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 0px;
    }

    & > div {
      flex-direction: column;
    }

    div.list {
      width: calc(100% - 40px);
      margin: auto;

      ul {
        width: 100%;
        margin: auto;
      }

      ul li {
        font-size: 21px;
      }

      /* h3 {
        margin-bottom: 10px;
      } */

      .row {
        flex-direction: column;
      }
    }

    div.images {
      display: none;
    }
  }
`;

export const SectionTargetAudience = styled.div`
  max-width: 1400px;
  min-width: 1200px;
  width: 100%;

  margin: 50px auto;

  h2 {
    margin: 0 auto;
    max-width: 900px;

    color: #f5f5f5;
    font-size: 48px;
    font-weight: 700;
    text-align: center;

    span {
      color: #ff955f;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .detail-target-audience {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      width: 50%;
    }

    p {
      font-size: 28px;
      color: #f5f5f5;
      text-align: center;
    }

    span {
      color: #ff955f;
    }

    div {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 1199px) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;

    h2 {
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 20px;

      font-size: 32px;
      margin-bottom: 20px;
    }

    .detail-target-audience {
      flex-direction: column;

      p {
        font-size: 21px;

        width: calc(100% - 40px);
        margin: auto;
        margin-bottom: 20px;
      }

      img {
        display: none;
      }
    }
  }
`;

export const SectionSubscriptions = styled.div`
  width: 100%;
  max-width: 1400px;
  min-width: 1200px;

  margin: 80px auto;

  h2 {
    margin: 0 auto;
    margin-bottom: 15px;

    font-size: 48px;
    font-weight: 700;

    span {
      color: #ff955f;
    }
  }

  h3 {
    font-size: 22;
  }

  p {
    font-size: 28px;
  }

  @media (max-width: 1199px) {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    width: calc(100% - 40px);

    h2 {
      font-size: 38px;
    }

    p {
      font-size: 21px;
    }
  }
`;

export const Slider = styled(SliderComponent)`
  margin: 60px auto;

  @media (max-width: 1199px) {
    margin: auto;
  }
`;

export const SectionGoToRegister = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  max-width: 1400px;
  min-width: 1200px;
  width: 100%;

  margin: 30px auto;

  div.left {
    /* width: 50%; */

    h2 {
      font-size: 48px;
      font-weight: 700;
      line-height: 50px;

      text-align: left;
    }

    h3 {
      font-size: 22px;
      text-align: left;

      margin-top: 20px;
    }

    button {
      margin: 30px 0;
    }
  }

  div.right {
    /* width: 50%; */
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 1199px) {
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    width: calc(100% - 40px);

    div.right {
      img {
        display: none;
      }
    }
  }
`;
