import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    outline: 0;
  }

  body {
    background: #f5f5f5;
    color: #151515;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }


  :root {
    --st-modal-boxShadow: 0 10px 50px rgba(0, 0, 0, 0.8);
    --st-modal-borderRadius: 15px;

    --st-modal-transformVisible: translate3d(-50%, -50%, 0) scale(1) rotateX(0);

    --st-dialog-maxWidth: 900px;

    @media (max-width: 1199px) {
      --st-dialog-baseWidth: 90%;
      --st-dialog-maxWidth: 90%;
    }
  }


`;
